import { Item } from '@smu-chile/pkg-unimarc-hooks'

/**
 * Returns contentful item content type
 * @param item - contentful item
 * @returns {string}
 */
export const getContentfulItemContentType = (item: Item): string => {
  return item?.sys?.contentType?.sys.id ?? ''
}
