import { ReactNode } from 'react'
import { Item } from '@smu-chile/pkg-unimarc-hooks'

/**
 * Return a custom render function by specific key
 * @param renderKey - render key type
 * @param renderFactory - callable function to create render list
 * @returns {{ render: (item: Item) => ReactNode}}
 *
 * @example
 * const { render } = useRenderItem('contentType', () => {
 *   return {
 *     contentTypeId: renderContentType
 *   }
 * });
 *
 * return render(item)
 */
export function useRenderItem(
  renderKey: 'contentType' | 'featureContentItem',
  renderFactory: () => Record<string, (item: Item) => ReactNode>
): { render: (item: Item) => ReactNode } {
  const renderId = (item: Item) => {
    if (renderKey === 'featureContentItem') {
      return `${item.fields?.itemFormat}`
    }

    return `${item.sys?.contentType?.sys?.id}`
  }

  const render = (item: Item) => {
    const renders = renderFactory()

    const id = renderId(item)
    const render = renders[id]

    if (render) {
      return render(item)
    }

    return null
  }

  return { render }
}
