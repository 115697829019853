import { useEffect, useState } from 'react'
import { Item } from '@smu-chile/pkg-unimarc-hooks'
import { getContentfulItemContentType } from 'shared/helpers/getContentfulItemContentType'
import { getContentfulItemField } from 'shared/helpers/getContentfulItemField'

export type UseMyAccountBlocksProps = {
  items?: Array<Item>
  membership?: string
}

export type useMyAccountBlocksResult = {
  blocks?: Array<Item>
}

/**
 * Return a blocks list mapped from contentful entries and
 * membership status
 * @param items - list of contentful items
 * @param membership - user membership status
 * @returns {useMyAccountBlocksResult}
 *
 * @example
 * const { blocks } = useMyAccountBlocks({ items });
 */
export function useMyAccountBlocks({
  items,
  membership
}: UseMyAccountBlocksProps): useMyAccountBlocksResult {
  const [blocks, setBlocks] = useState<Array<Item>>(null)

  const mapMenuBlock = (item: Item) => {
    const contentType = getContentfulItemContentType(item)
    const itemItems = getContentfulItemField<Array<Item>>(item, 'items')

    if (contentType === 'unimediosMainBanner') {
      const items = itemItems?.filter(filterMenuItemBlock)
      return {
        ...item,
        fields: {
          ...item?.fields,
          items
        },
        items
      }
    }

    return item
  }

  const filterMenuItemBlock = (item: Item) => {
    const url = getContentfulItemField<string>(item, 'url')
    if (url === '/Membership') {
      return !!membership
    }

    return true
  }

  const filterMembershipBlock = (item: Item) => {
    const label = getContentfulItemField<string>(item, 'label')
    if (membership) {
      if (membership !== 'active') {
        return label === 'Renovar'
      }

      return false
    }

    return label === 'Ser diamante'
  }

  const mapMembershipBlock = (item: Item) => {
    const contentType = getContentfulItemContentType(item)
    const itemFormat = getContentfulItemField<string>(item, 'itemFormat')
    const itemItems = getContentfulItemField<Array<Item>>(item, 'items')

    if (
      contentType === 'featureContentItem' &&
      itemFormat === 'boton membresia'
    ) {
      const items = itemItems?.filter(filterMembershipBlock)
      return {
        ...item,
        fields: {
          ...item?.fields,
          items
        },
        items
      }
    }

    return item
  }

  useEffect(() => {
    if (items) {
      const blocks = items.map(mapMenuBlock).map(mapMembershipBlock)
      setBlocks(blocks)
    }
  }, [items, membership])

  return { blocks }
}
