import { Column, Container, Text } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export const sideNavTitleColors = {
  descriptionColor: getGlobalStyle('--color-neutral-gray-dark')
}

export const sideNavTitleIds = {
  descriptionText: (id?: string) => {
    return getBemId('sideNav', 'titleColumn', id)
  },
  titleColumn: (id?: string) => {
    return getBemId('sideNav', 'titleColumn', id)
  },
  titleText: (id?: string) => {
    return getBemId('sideNav', 'titleText', id)
  }
}

export type SideNavTitlesProps = {
  description?: string
  id?: string
  title?: string
}

export const SideNavTitles = ({
  description,
  id,
  title
}: SideNavTitlesProps) => {
  return (
    <Column
      gap={12}
      id={sideNavTitleIds.titleColumn(id)}
    >
      {title && (
        <Container customHeight='23px'>
          <Text
            fontSize='lg'
            fontWeight='medium'
            id={sideNavTitleIds.titleColumn(id)}
            lineHeight='20.16px'
          >
            {title}
          </Text>
        </Container>
      )}

      {description && (
        <Text
          customColor={sideNavTitleColors.descriptionColor}
          fontSize='md'
          fontWeight='regular'
          id={sideNavTitleIds.titleColumn(id)}
          lineHeight='17.64px'
        >
          {description}
        </Text>
      )}
    </Column>
  )
}
