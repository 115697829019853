import { useEffect, useState } from 'react'
import {
  Item,
  useQuery,
  useSession,
  useUserSavings,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import { getContentfulMyAccount } from 'shared/contentful/myAccount/getContentfulMyAccount'
import { useMyAccountActions } from './useMyAccountActions'
import { useMyAccountLevel } from './useMyAccountLevel'
import { useMyAccountBlocks } from './useMyAccountBlocks'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

export type UseMyAccountProps = {
  screen: 'desktop' | 'mobile'
}

export type UseMyAccountResult = {
  actions?: Item
  blocks?: Array<Item>
  isLoading?: boolean
  level?: Item
  levelIcon?: Item
  userFirstName?: string
}

/**
 * Return a blocks list mapped from contentful entries and
 * membership status
 * @param items - list of contentful items
 * @param membership - user membership status
 * @returns {UseMyAccountResult}
 *
 * @example
 * const { actions } = useMyAccount({ items });
 */

export function useMyAccount({
  screen
}: UseMyAccountProps): UseMyAccountResult {
  const [components, setComponents] = useState<Array<Item>>([])
  const [infoBlocks, setInfoBlocks] = useState<Array<Item>>([])

  const session = useSession()

  const user = useUserWithMembership({
    enabled: session.isLoggedIn
  })

  const savings = useUserSavings({
    reactQuery: {
      enabled: session.isLoggedIn,
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })

  const myAccount = useQuery(
    'myAccount',
    () => {
      return getContentfulMyAccount()
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const { actions } = useMyAccountActions({
    items: infoBlocks,
    savings: savings.data?.totalSavings
  })

  const { blocks } = useMyAccountBlocks({
    items: components,
    membership: user.data?.membership
  })

  const { level, levelIcon } = useMyAccountLevel({
    items: infoBlocks,
    level: `${user.data?.level}`
  })

  useEffect(() => {
    if (screen === 'desktop') {
      setComponents(myAccount.data?.fields?.componentsDesktop as Array<Item>)
      setInfoBlocks(myAccount.data?.fields?.infoBlocksDesktop as Array<Item>)
    }

    if (screen === 'mobile') {
      setComponents(myAccount.data?.fields?.components as Array<Item>)
      setInfoBlocks(myAccount.data?.fields?.infoBlocks as Array<Item>)
    }
  }, [myAccount.data])

  return {
    actions,
    blocks,
    isLoading: myAccount.isLoading || user.isLoading,
    level,
    levelIcon,
    userFirstName: user.data?.firstName || 'Invitado'
  }
}
