import { isValidArrayWithData, Item } from '@smu-chile/pkg-unimarc-hooks'
import { MyAccountMembershipBanner } from '../MyAccountMembershipBanner'
import { useRenderItem } from 'components/MyAccountPage/helpers/useRenderItem'

export enum FeatureContentItemFormats {
  MembershipButtons = 'boton membresia'
}

export enum FeatureContentItemTypes {
  NavigationItem = 'navigationItem'
}

export type MyAccountFeatureContentProps = {
  format?: string
  items?: Array<Item>
}

export const MyAccountFeatureContent = ({
  format,
  items
}: MyAccountFeatureContentProps) => {
  const { render } = useRenderItem('contentType', () => {
    return {
      [FeatureContentItemTypes.NavigationItem]: renderMembershipBanner
    }
  })

  const renderMembershipBanner = (navigationItem: Item) => {
    return (
      <>
        {format === FeatureContentItemFormats.MembershipButtons && (
          <MyAccountMembershipBanner item={navigationItem} />
        )}
      </>
    )
  }

  return (
    <>
      {isValidArrayWithData(items) &&
        items.map((item) => {
          return <>{render(item)}</>
        })}
    </>
  )
}
