import { useEffect, useState } from 'react'
import { Item } from '@smu-chile/pkg-unimarc-hooks'
import { getContentfulItemField } from 'shared/helpers/getContentfulItemField'

export type SideNavbarOption = {
  action: string
  description: string
  options: Array<Item>
  title: string
}

export type UseMyAccountSideNavbarOptionsProps = {
  items?: Array<Item>
}

export function useMyAccountSideNavbarOptions({
  items
}: UseMyAccountSideNavbarOptionsProps) {
  const [options, setOptions] = useState<Array<SideNavbarOption>>(null)

  const filterByNavigation = (item: Item) => {
    return getContentfulItemField<string>(item, 'url')
  }

  const filterItems = (item: Item) => {
    return (
      ['unimediosMainBanner'].includes(item.sys?.contentType?.sys?.id) &&
      ['Vertical'].includes(item.fields?.orientation)
    )
  }

  const filterOptions = (item: SideNavbarOption) => {
    return item.options.length > 0
  }

  const mapOptions = (item: Item) => {
    const action = getContentfulItemField(item, 'action')
    const description = getContentfulItemField(item, 'subtitle', '')
    const title = getContentfulItemField(item, 'title', '')
    const items = getContentfulItemField(item, 'items', [])

    return {
      action,
      description,
      title,
      options: items.filter(filterByNavigation).map(mapOptionsItems)
    }
  }

  const mapOptionsItems = (item: Item) => {
    return item.fields
  }

  const searchOptions = () => {
    return items.filter(filterItems).map(mapOptions).filter(filterOptions)
  }

  useEffect(() => {
    if (items) {
      const options = searchOptions()

      setOptions(options)
    }
  }, [items])

  return { options }
}
