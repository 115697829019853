import { BannerCard } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Item, trigger } from '@smu-chile/pkg-unimarc-hooks'
import { MembershipBlock } from 'components/MembershipBlock'
import { mapMembershipBlock } from './helpers/mapMembershipBlock'

export type MyAccountMembershipBannerProps = {
  item?: Item
}

export const MyAccountMembershipBanner = ({
  item
}: MyAccountMembershipBannerProps) => {
  const {
    buttonText,
    labelColorBackground,
    labelColorText,
    labelIconUrl,
    labelText,
    subtitle,
    title
  } = mapMembershipBlock(item)

  const handleBannerButtonClick = () => {
    trigger({
      eventType: 'openMembershipModal',
      data: { eventContext: 'Mi cuenta' }
    })
  }

  return (
    <>
      <BigScreen>
        <MembershipBlock
          buttonLabel={buttonText}
          labelColorBackground={labelColorBackground}
          labelColorText={labelColorText}
          labelIcon={labelIconUrl}
          labelText={labelText}
          subtitle={subtitle}
          title={title}
        />
      </BigScreen>

      <SmallScreen>
        <BannerCard
          buttonLabel={buttonText}
          label={labelText}
          labelBackgroundColor={labelColorBackground}
          labelFontColor={labelColorText}
          labelPictureSrc={labelIconUrl}
          onButtonClick={handleBannerButtonClick}
          subtitle={subtitle}
          title={title}
        />
      </SmallScreen>
    </>
  )
}
