import { Column, Container, Row } from '@smu-chile/pkg-unimarc-components'
import {
  getGlobalStyle,
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { isValidArrayWithData, useSession } from '@smu-chile/pkg-unimarc-hooks'
import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { MembershipHeaderBrowse, SideNav } from 'components'
import { MyAccountFeatureContent } from 'components/MyAccountBlocks/components/MyAccountFeatureContent'
import { useMyAccount } from 'components/MyAccountPage/helpers/useMyAccount'
import { useMyAccountMembershipBanner } from 'components/MyAccountPage/helpers/useMyAccountMembershipBanner'
import { IMembershipDataCF } from 'shared/interfaces/IMembership'

interface ContainerPagesProps {
  children: React.ReactNode
  membershipDataCF?: IMembershipDataCF
  /**@deprecated */
  userData?: ResponseOrderFormProps
}

export const ContainerPages = ({
  children,
  membershipDataCF
}: ContainerPagesProps): React.ReactElement => {
  const { isLoading, isLoggedIn } = useSession({
    reactQuery: { enabled: true }
  })

  const myAccount = useMyAccount({ screen: 'desktop' })
  const { actions, blocks, level, levelIcon, userFirstName } = myAccount

  const { membershipBanner } = useMyAccountMembershipBanner({
    items: blocks
  })

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-background-white')}
      isWrap
      justifyContent='center'
    >
      <BigScreen>
        <Row
          customWidth='90'
          isWrap
          maxWidth='1200px'
        >
          {(isLoading || isLoggedIn) && (
            <MembershipHeaderBrowse
              actions={actions?.fields?.items}
              color={level?.fields?.backgroundColor}
              fontColor={level?.fields?.fontColor}
              isLoading={isLoading || myAccount.isLoading}
              level={level?.fields?.description}
              membershipDataCF={membershipDataCF}
              pictureSrc={levelIcon?.fields?.image?.fields?.file?.url}
              userFirstName={userFirstName}
            />
          )}

          <Column
            customWidth='24.11'
            isWrap
            position='sticky'
            tagName='aside'
            top='74'
          >
            {membershipBanner &&
              isValidArrayWithData(membershipBanner.fields?.items) && (
                <Container padding='16px 28px 0 0'>
                  <MyAccountFeatureContent
                    format='boton membresia'
                    items={membershipBanner.fields?.items}
                  />
                </Container>
              )}

            <SideNav />
          </Column>

          <Column
            customWidth='75.89'
            isWrap
            margin='16px 0'
            tagName='section'
          >
            {children}
          </Column>
        </Row>
      </BigScreen>

      <SmallScreen>
        <Container
          backgroundColor={getGlobalStyle('--color-background-white')}
          justifyContent='center'
        >
          <Column maxWidth={getGlobalStyle('--width-max-mobile')}>
            {children}
          </Column>
        </Container>
      </SmallScreen>
    </Container>
  )
}
