import { useEffect, useState } from 'react'
import { Item, searchContentfulItemsBy } from '@smu-chile/pkg-unimarc-hooks'

const levelBlockIds = {
  clubunimarc: 'Socio Club',
  diamante: 'Socio Diamante',
  oro: 'Socio Oro',
  platino: 'Socio Platino',
  default: 'Sin nivel'
}

export type UseMyAccountLevelProps = {
  items?: Array<Item>
  level?: string
}

export type UseMyAccountLevelResult = {
  level?: Item
  levelIcon?: Item
}

/**
 * Return a level entry from contentful entries
 * @param items - list of contentful items
 * @returns {UseMyAccountLevelResult}
 *
 * @example
 * const { level, levelIcon } = useMyAccountLevel({ items });
 */
export function useMyAccountLevel({
  items,
  level: userLevel
}: UseMyAccountLevelProps): UseMyAccountLevelResult {
  const [level, setLevel] = useState<Item>(null)
  const [levelIcon, setLevelIcon] = useState<Item>(null)

  const searchLevelId = () => {
    return levelBlockIds[userLevel] ?? levelBlockIds.clubunimarc
  }

  const searchLevel = () => {
    const [levels] = searchLevels()
    const level = searchLevelId()
    const items = levels?.fields?.items as Array<Item>
    return searchContentfulItemsBy(items ?? [], 'blockId', level)
  }

  const searchLevelIcon = () => {
    const [level] = searchLevel()
    const items = level?.fields?.items as Array<Item>
    return searchContentfulItemsBy(items ?? [], 'label', 'Icon')
  }

  const searchLevels = () => {
    return searchContentfulItemsBy(items, 'blockId', 'Headers')
  }

  useEffect(() => {
    if (items) {
      const [level] = searchLevel()
      setLevel(level)

      const [leveIcon] = searchLevelIcon()
      setLevelIcon(leveIcon)
    }
  }, [items, userLevel])

  return { level, levelIcon }
}
