import { Item } from '@smu-chile/pkg-unimarc-hooks'

// only disabled to item-fields from pkg hooks
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ItemFieldsTypes = string | number | boolean | object | any | null

/**
 * Returns contentful item field value
 * @param item - contentful item
 * @param field - contentful item field name
 * @param safe - safety default value
 * @returns {<T = ItemFieldsTypes>}
 */
export const getContentfulItemField = <T = ItemFieldsTypes>(
  item: Item,
  field: string,
  safe?: ItemFieldsTypes
): T => {
  if (item && item.fields && item.fields[field]) {
    return item.fields[field] as T
  }

  if (safe) {
    return safe as T
  }

  return null
}
