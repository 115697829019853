import { useEffect, useState } from 'react'
import { currencyFormat, Item } from '@smu-chile/pkg-unimarc-hooks'

export const allowedActionsIds = ['Botones headers', 'Headers buttons']

export type UseMyAccountActionsProps = {
  items?: Array<Item>
  savings?: number
}

export type UseMyAccountActionsResult = {
  actions?: Item
}

/**
 * Return a actions list mapped from contentful entries and
 * saving total
 * @param items - list of contentful items
 * @param savings - user savings total
 * @returns {UseMyAccountActionsResult}
 *
 * @example
 * const { actions } = useMyAccountActions({ items });
 */
export function useMyAccountActions({
  items,
  savings = 0
}: UseMyAccountActionsProps): UseMyAccountActionsResult {
  const [actions, setActions] = useState<Item>(null)

  const filterByBlockId = (item: Item) => {
    return allowedActionsIds.includes(`${item.fields?.blockId}`)
  }

  const mapActions = (item: Item) => {
    return {
      ...item,
      fields: {
        ...item?.fields,
        items: item.fields?.items?.map(mapActionItems)
      }
    }
  }

  const mapActionItems = (item: Item) => {
    if (['Ahorrado'].includes(item.fields?.label)) {
      return {
        ...item,
        fields: {
          ...item?.fields,
          label: `Ahorrado ${currencyFormat({
            number: savings,
            bool: false
          })}`
        }
      }
    }

    return item
  }

  const searchActions = () => {
    return items.filter(filterByBlockId).map(mapActions)
  }

  useEffect(() => {
    if (items) {
      const [actions] = searchActions()
      setActions(actions)
    }
  }, [items, savings])

  return { actions }
}
