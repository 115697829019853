import { useEffect, useState } from 'react'
import { Item } from '@smu-chile/pkg-unimarc-hooks'
import { getContentfulItemContentType } from 'shared/helpers/getContentfulItemContentType'
import { getContentfulItemField } from 'shared/helpers/getContentfulItemField'

export type UseMyAccountMembershipBannerProps = {
  items?: Array<Item>
  membership?: string
}

export type UseMyAccountMembershipBannerResult = {
  membershipBanner: Item
}

/**
 * Return a membership banner entry from contentful entries
 * @param items - list of contentful items
 * @returns {UseMyAccountMembershipBannerResult}
 *
 * @example
 * const { membershipBanner } = useMyAccountMembershipBanner({ items });
 */
export function useMyAccountMembershipBanner({
  items
}: UseMyAccountMembershipBannerProps): UseMyAccountMembershipBannerResult {
  const [membershipBanner, setMembershipBanner] = useState<Item>(null)

  const filterByContentType = (item: Item) => {
    const contentType = getContentfulItemContentType(item)
    return contentType === 'featureContentItem'
  }

  const filterByItemFormat = (item: Item) => {
    const itemFormat = getContentfulItemField<string>(item, 'itemFormat')
    return itemFormat === 'boton membresia'
  }

  useEffect(() => {
    if (items) {
      const [membershipBanner] = items
        .filter(filterByContentType)
        .filter(filterByItemFormat)
      setMembershipBanner(membershipBanner)
    }
  }, [items])

  return { membershipBanner }
}
