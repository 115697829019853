import {
  getContentfulData,
  ContentfulResponse,
  IContentfulResponse
} from '@smu-chile/pkg-unimarc-hooks'

export const getContentfulMyAccount = async () => {
  const contentful = await getContentfulData({
    options: {
      [`content_type`]: 'myAccount',
      [`sys.id`]: '4cUzqEKUpCyao2YjDzMfo5',
      [`include`]: '5'
    },
    reactQuery: {
      queryKey: 'myAccountUnimarc'
    }
  })

  const response = new ContentfulResponse(contentful as IContentfulResponse)
  const populate = response.populateEntries().getResponse()

  const [myAccount] = populate.items

  return myAccount
}
