import React, { useEffect, useState } from 'react'
import {
  Column,
  Container,
  Divider,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { NavItem } from './components/NavItem'
import {
  isValidArrayWithData,
  useSession,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import { useMyAccount } from 'components/MyAccountPage/helpers/useMyAccount'
import { useMyAccountSideNavbarOptions } from './helper/useMyAccountSideNavbarOptions'
import { SideNavLoader } from './SideNavLoader'
import { SideNavTitles } from './components/SideNavTitles'

export const SideNav = (): React.ReactElement => {
  const [sideNavOptions, setSideNavOptions] = useState([])
  const [showMembershipMenu, setShowMembershipMenu] = useState(true)
  const { isLoggedIn, isLoading } = useSession()
  const { data: dataMembership, isLoading: isLoadingMembership } =
    useUserWithMembership()

  useEffect(() => {
    if (
      dataMembership?.error === 'not found' ||
      dataMembership?.status === 404 ||
      dataMembership?.status === 400 ||
      dataMembership?.status.toString() === '404' ||
      dataMembership?.status.toString() === '400' ||
      (!dataMembership?.membership && !isLoadingMembership)
    ) {
      setShowMembershipMenu(false)
    }
  }, [dataMembership, isLoadingMembership])

  const myAccount = useMyAccount({ screen: 'desktop' })

  const myAccountOptions = useMyAccountSideNavbarOptions({
    items: myAccount.blocks
  })

  useEffect(() => {
    setSideNavOptions(myAccountOptions.options)
  }, [myAccountOptions.options])

  if (isLoading || myAccount.isLoading || sideNavOptions?.length < 1) {
    return <SideNavLoader />
  }

  return (
    <>
      {sideNavOptions?.length > 0 &&
        sideNavOptions?.map(({ title, description, options }, idx) => {
          return title?.toUpperCase() === 'LOGOUT' && !isLoggedIn ? null : (
            <Container
              backgroundColor={getGlobalStyle('--color-background-white')}
              border={`0.3px solid ${getGlobalStyle(
                '--color-neutral-gray-divider'
              )}`}
              borderRadius={getGlobalStyle('--border-radius-md')}
              id={getBemId('sideNav', 'optionContainer', idx.toString())}
              justifyContent='center'
              key={title}
              margin={idx === 0 ? '16px 0 0 0' : '24px 0 0 0'}
              maxWidth='90%'
              minWidth='253px'
              padding={!title ? '0' : '20px 0 16px'}
            >
              <Row id={getBemId('sideNav', 'optionRow', idx.toString())}>
                <Column
                  id={getBemId('sideNav', 'optionColumn', idx.toString())}
                >
                  {title && title !== 'logout' && (
                    <Container padding='0 12px'>
                      <SideNavTitles
                        description={description}
                        id={`${idx}`}
                        title={title}
                      />
                    </Container>
                  )}

                  {isValidArrayWithData(options) &&
                    options.map((fields, index) => {
                      return (fields?.iconText?.toUpperCase() ===
                        'DIAMONDMEMBERSHIP' &&
                        !showMembershipMenu) ||
                        (fields?.iconText?.toUpperCase() === 'LOGOUT2' &&
                          !isLoggedIn) ? null : (
                        <React.Fragment key={fields?.label}>
                          <NavItem
                            active={fields?.active}
                            icon={fields?.iconText}
                            iconPicture={fields?.icon?.fields?.file?.url}
                            isLogout={title === 'logout'}
                            labelColorBackground={fields?.labelColorBackground}
                            labelColorText={fields?.labelColorText}
                            labelText={fields?.labelNewText}
                            sendEvent={fields?.eventClick}
                            showLabel={fields?.showLabel}
                            strokeWidthIcon='1.2'
                            text={fields?.buttonText ?? fields?.label}
                            url={fields?.url}
                          />
                          {index + 1 !== options.length && (
                            <Container
                              id={getBemId(
                                'sideNav',
                                'dividerContainer',
                                `${idx}-${index}`
                              )}
                              padding='0 12px'
                            >
                              <Divider
                                borderHeight='xs'
                                color={getGlobalStyle(
                                  '--color-neutral-gray-light'
                                )}
                                verticalMargin={0}
                              />
                            </Container>
                          )}
                        </React.Fragment>
                      )
                    })}
                </Column>
              </Row>
            </Container>
          )
        })}
    </>
  )
}
