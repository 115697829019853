import {
  Button,
  Column,
  Container,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Image } from '@smu-chile/pkg-unimarc-components/stories/organisms/AddToListModal/Image'
import { trigger } from '@smu-chile/pkg-unimarc-hooks'

export const membershipBlockStyles = {
  backgroundColor: getGlobalStyle('--color-neutral-gray-white'),
  borderRadius: getGlobalStyle('--border-radius-md'),
  buttonBorderRadius: getGlobalStyle('--border-radius-full'),
  buttonFontSize: getGlobalStyle('--font-size-sm'),
  buttonFontWeight: getGlobalStyle('--font-weight-medium')
}

export const membershipBlockIds = {
  button: getBemId('membershipBlock', 'button'),
  tag: getBemId('membershipBlock', 'tag'),
  text: getBemId('membershipBlock', 'text')
}

export type MembershipBlockProps = {
  buttonLabel?: string
  labelColorBackground?: string
  labelColorText?: string
  labelIcon?: string
  labelText?: string
  title?: string
  subtitle?: string
}

export const MembershipBlock = ({
  buttonLabel,
  labelColorBackground,
  labelColorText,
  labelIcon,
  labelText,
  title,
  subtitle
}: MembershipBlockProps) => {
  const handleClick = () => {
    trigger({
      eventType: 'openMembershipModal',
      data: { eventContext: 'Mi cuenta' }
    })
  }

  return (
    <Column
      backgroundColor={membershipBlockStyles.backgroundColor}
      borderRadius={membershipBlockStyles.borderRadius}
      gap={12}
      padding='12px 10px'
    >
      <Column gap={4}>
        <Row
          alignItems='center'
          backgroundColor={labelColorBackground}
          borderRadius='22.36px'
          gap={5}
          id={membershipBlockIds.tag}
          width='auto'
        >
          <Spacer.Vertical customSize={1} />

          <Container width='auto'>
            <Image
              altPicture='Icono Socio'
              customSize='12px'
              srcPicture={labelIcon}
            />
          </Container>

          <Text
            customColor={labelColorText}
            fontSize='sm'
            fontWeight='semibold'
            lineHeight='15.12px'
          >
            {labelText}
          </Text>

          <Spacer.Vertical customSize={1} />
        </Row>

        <Column id={membershipBlockIds.text}>
          <Container customHeight='23px'>
            <Text
              fontSize='md'
              fontWeight='medium'
              lineHeight='17.64px'
            >
              {title}
            </Text>
          </Container>

          <Text
            fontSize='sm'
            fontWeight='regular'
            lineHeight='15.12px'
          >
            {subtitle}
          </Text>
        </Column>
      </Column>

      <Button
        borderRadius={membershipBlockStyles.buttonBorderRadius}
        fontSize={membershipBlockStyles.buttonFontSize}
        fontWeight={membershipBlockStyles.buttonFontWeight}
        fullWidth
        height='32px'
        id={membershipBlockIds.button}
        label={buttonLabel}
        onClick={handleClick}
        type='secondary'
      />
    </Column>
  )
}
