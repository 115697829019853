import { getContentfulFileUrl, Item } from '@smu-chile/pkg-unimarc-hooks'
import { getContentfulItemField } from 'shared/helpers/getContentfulItemField'

export const mapMembershipBlock = (item: Item) => {
  const buttonText = getContentfulItemField<string>(item, 'buttonText')
  const labelText = getContentfulItemField<string>(item, 'label')
  const icon = getContentfulItemField<Item>(item, 'icon')
  const showButton = getContentfulItemField<boolean>(item, 'showbuttom')
  const showLabel = getContentfulItemField<boolean>(item, 'showLabel')

  return {
    buttonText: showButton ? buttonText : '',
    labelColorBackground: getContentfulItemField(item, 'labelColorBackground'),
    labelColorText: getContentfulItemField(item, 'labelColorText'),
    labelIconUrl: getContentfulFileUrl(icon),
    labelText: showLabel ? labelText : '',
    subtitle: getContentfulItemField(item, 'subtitulo2'),
    title: getContentfulItemField(item, 'subtitulo')
  }
}
